import { Box, Spinner } from '@chakra-ui/react'
import { UserCards } from '../components/Overview'
import useAuth from '../hooks/useAuth'
import { useUserAndChildrenData } from '../hooks/useSwr'
import colors from '../constants/colors'

export default function Overview() {
  const { user } = useAuth()

  const { userDataAndChildren: data, isLoading } = useUserAndChildrenData(
    user?.uid!,
  )

  return (
    <>
      {isLoading ? (
        <Spinner
          color={colors.greyLight}
          w="80px"
          h="80px"
          position="absolute"
          fontWeight="bold"
          top="50%"
          left="55%"
        />
      ) : (
        <Box w="100%" pt="175px" pb="50px" h="max-content" position="relative">
          {data && <UserCards data={data} />}
        </Box>
      )}
    </>
  )
}
