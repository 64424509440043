import { Ellipsis, Settings2, Trash2 } from 'lucide-react'
import { useRef } from 'react'
import { Row } from 'react-table'
import { IUser } from '../../types/types'
import colors from '../../constants/colors'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react'
import { deleteUserByUID } from '../../lib/firebase/api'
import { mutate } from 'swr'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import routs from '../../constants/routes'

export const ActionMenuCell = ({ row }: { row: Row<IUser> }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const cancelRef = useRef<HTMLButtonElement>(null)

  const handleDeleteUser = async (uid: string) => {
    try {
      await deleteUserByUID(uid)
      await mutate('allUsers')
    } catch (error) {
      console.error('Error deleting user:', error)
    }
  }
  const handleConfirmDelete = async () => {
    await handleDeleteUser(row.original.uid)
    onClose()
  }

  const handleCLickEditUser = () => {
    navigate(routs.EDIT_USER.replace(':id', row.original.uid))
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Ellipsis />}
          variant="outline"
          color={colors.white}
          _hover={{ color: colors.black, bg: colors.white }}
        />
        <MenuList
          bg={colors.greyExtraDark}
          style={{ border: 'none' }}
          boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
        >
          <MenuItem
            bg={colors.greyExtraDark}
            icon={<Settings2 />}
            _hover={{ bg: colors.grey }}
            onClick={handleCLickEditUser}
          >
            Edit
          </MenuItem>
          {user?.uid !== row.original.uid && (
            <MenuItem
              bg={colors.greyExtraDark}
              p="5px"
              icon={<Trash2 color="red" strokeWidth={2} />}
              onClick={onOpen}
              _hover={{ bg: colors.grey }}
            >
              Delete User
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can not undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleConfirmDelete}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
