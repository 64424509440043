import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  components: {
    Input: {
      baseStyle: {
        field: {
          fontSize: '16px',
          fontWeight: '400',
          color: 'white',
          bg: 'transparent !important',
          _placeholder: {
            color: 'white',
          },
          _hover: {
            bg: 'transparent !important',
          },
          _focus: {
            borderColor: 'blue.500',
          },
          _autofill: {
            textFillColor: 'white',
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: 'white !important',
            background: 'transparent !important',
            color: 'white !important',
            fontSize: '14px !important',
          },
        },
      },
    },
    styles: {
      global: {
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
          {
            transition: 'background-color 5000s ease-in-out 0s',
            WebkitTextFillColor: 'white !important',
            background: 'transparent !important',
          },
      },
    },
  },
})
