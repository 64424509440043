import { Flex, Text } from '@chakra-ui/react'
import { UserDocument } from '../../types/types'
import colors from '../../constants/colors'
import { memo } from 'react'

export const DocumentCard = memo(({ document }: { document: UserDocument }) => {
  const { documentName, documentValue } = document

  return (
    <Flex
      cursor="pointer"
      onClick={() => window.open(documentValue, '_blank')}
      justifyContent="center"
      alignItems="center"
      bg={colors.greyBackground}
      borderRadius="15px"
      h="100px"
      transition="transform 0.3s ease-in-out"
      _hover={{
        transform: 'scale(0.99)',
      }}
    >
      <Text color={colors.white} fontSize="xl">
        {documentName}
      </Text>
    </Flex>
  )
})

DocumentCard.displayName = 'DocumentCard'
