import { useMemo } from 'react'
import { useTable, Column } from 'react-table'
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react'
import { IUser } from '../../types/types'
import colors from '../../constants/colors'
import { UserInfoCell } from './UserInfoCell'
import { ActionMenuCell } from './ActionMenuCell'

const columns: Column<IUser>[] = [
  {
    Header: 'Name',
    Cell: ({ row }: any) => <UserInfoCell row={row} />,
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'License',
    accessor: 'license',
  },
  {
    Header: 'Rates',
    accessor: 'rates',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Action',
    Cell: ({ row }: any) => <ActionMenuCell row={row} />,
  },
]

export default function UsersTable({ users }: { users: IUser[] }) {
  const data = useMemo(() => users, [users])
  const tableInstance = useTable({ columns, data })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <Box overflowX="auto" w="100%" pos="relative">
      <Table {...getTableProps()} w={'100%'} color={colors.white}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr
              {...headerGroup.getHeaderGroupProps()}
              style={{ textAlign: 'left' }}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()} bg={colors.grey}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}
