import { Divider, List, ListItem, VStack, Text, Button } from '@chakra-ui/react'
import {
  FileBarChartIcon,
  HandCoins,
  Home,
  LogOut,
  Settings,
} from 'lucide-react'
import styled from '@emotion/styled'
import colors from '../../constants/colors'
import { logout } from '../../lib/firebase/actions'
import { useNavigate } from 'react-router-dom'
import routs from '../../constants/routes'
import { Link } from 'react-router-dom'
import { useUserData } from '../../hooks/useSwr'
import useAuth from '../../hooks/useAuth'

const StyledLink = styled(Link)<{ pathname: string }>`
  text-decoration: none;
  color: ${(props) =>
    props.pathname === props.to ? colors.white : colors.greyExtraLight};
  display: flex;
  align-items: center;
  padding: 2px;
  column-gap: 10px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: ${colors.white};
  }
`

const links = [
  { path: routs.OVERVIEW, label: 'Overview', icon: <Home /> },
  { path: '/hub', label: 'Hub', icon: <FileBarChartIcon /> },
  { path: '/earnings', label: 'Earnings', icon: <HandCoins /> },
]

export const Sidebar = () => {
  const auth = useAuth()
  const { user } = useUserData(auth.user?.uid!)
  const navigate = useNavigate()
  const pathname = window.location.pathname

  const handleSignOut = async () => {
    try {
      await logout()
      navigate(routs.LOGIN)
    } catch (error) {
      console.error('Sign out error:', error)
    }
  }

  return (
    <VStack
      w="100%"
      minW="310px"
      h={{ base: '50vh', md: '100vh' }}
      bg={'#2E2E2E'}
      p="100px 30px 30px 30px"
      justifyContent="space-between"
    >
      <List spacing={10} w="100%">
        {links.map((link) => (
          <ListItem key={link.path}>
            <StyledLink to={link.path} pathname={pathname}>
              {link.icon}
              {link.label}
            </StyledLink>
          </ListItem>
        ))}
      </List>
      <List spacing={4} w="100%">
        <Divider />
        {user?.is_app_admin && (
          <StyledLink to={routs.ADMIN} pathname={pathname}>
            <Settings />
            <Text>Settings</Text>
          </StyledLink>
        )}

        <Button
          onClick={handleSignOut}
          variant="outline"
          color={colors.greyExtraLight}
          border="none"
          columnGap="10px"
          w="max-content"
          p="5px"
          fontSize="20px"
          fontWeight="600"
          justifyContent="flex-start"
          _hover={{ bg: 'transparent', color: colors.white }}
        >
          <LogOut />
          <Text>Logout</Text>
        </Button>
      </List>
    </VStack>
  )
}
