'use client'
import { useRef, useState } from 'react'
import { Button, Input, useToast, Avatar, Flex } from '@chakra-ui/react'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../../lib/firebase/config'

interface AvatarUploadProps {
  setAvatarUrl: (url: string) => void
  avatar?: string
}

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  setAvatarUrl,
  avatar,
}) => {
  const [uploading, setUploading] = useState(false)
  const [avatarUrl, setAvatarUrlLocal] = useState('')
  const toast = useToast()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files ? event.target.files[0] : null
    if (!file) return

    const storageRef = ref(storage, `avatars/${file.name}`)
    setUploading(true)

    try {
      await uploadBytes(storageRef, file)
      const url = await getDownloadURL(storageRef)
      setAvatarUrl(url)
      setAvatarUrlLocal(url)
      toast({
        title: 'Avatar uploaded successfully.',
        description: "We've updated your avatar.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      console.error('Error uploading avatar:', error)
      toast({
        title: 'Error uploading avatar.',
        description:
          'There was an error uploading your avatar. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setUploading(false)
    }
  }
  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <>
      <Flex alignItems="center" gap={4}>
        <Avatar src={avatar || avatarUrl} />
        <Button onClick={handleButtonClick} isLoading={uploading}>
          Upload Avatar
        </Button>
      </Flex>
      <Input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        display="none"
        id="file-upload"
        ref={fileInputRef}
      />
    </>
  )
}
