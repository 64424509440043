import { useNavigate, useParams } from 'react-router-dom'
import { useDocuments, useUserData } from '../hooks/useSwr'
import UserForm, { UserFormData } from '../components/UserForm'
import { SubmitHandler } from 'react-hook-form'
import { useState } from 'react'
import { IUser } from '../types/types'
import { updateUser, updateUserDocuments } from '../lib/firebase/api'
import { Box } from '@chakra-ui/react'
import { BackButton } from '../components/commonComponents/BackButton'
import { mutate } from 'swr'

export default function EditUser() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { user, isLoading: isUserLoading } = useUserData(id ?? '')
  const { documents: userDocuments, isLoading: loadingDocuments } =
    useDocuments(id ?? '')

  const isLoading = isUserLoading || loadingDocuments || loading
  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    setLoading(true)
    try {
      const { documents, ...dataWithoutDocuments } = data
      await updateUser(user?.uid, dataWithoutDocuments)
      await mutate('allUsers')
      await updateUserDocuments(user?.uid, documents ?? [])
      await mutate(`documents/${user?.uid}`)
      navigate(-1)
    } catch (error) {
      console.error('Error updating user data:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box pt="100px" px="20px" pb="50px" pos="relative">
      <BackButton title="Back to Users Table" />
      <UserForm
        onSubmit={onSubmit}
        loading={isLoading}
        documents={userDocuments}
        user={user as IUser}
      />
    </Box>
  )
}
