import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import routes from '../../../constants/routes'
import { useUserData } from '../../../hooks/useSwr'

const PrivateAuthRoute = () => {
  const { user, loading } = useAuth()
  const { user: userData, isLoading } = useUserData(user?.uid!)

  if (!userData?.is_app_admin && !loading && !isLoading) {
    return <Navigate to={routes.NOT_FOUND} />
  }

  return <Outlet />
}

export default PrivateAuthRoute
