import React, { useEffect, useState } from 'react'
import { Flex, IconButton, Button } from '@chakra-ui/react'
import { Plus, X } from 'lucide-react'
import colors from '../../constants/colors'
import { UserFormLabel } from '../commonComponents/UserFormLabel'
import UserInput from './UserInput'
import { IHub, UserFormData } from '../../types/types'
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

interface DocumentManagerProps {
  initialHubs?: IHub[]
  setValue: UseFormSetValue<UserFormData>
  setError: UseFormSetError<UserFormData>
  clearErrors: UseFormClearErrors<UserFormData>
}

const DocumentManager = ({
  initialHubs,
  setValue,
  setError,
  clearErrors,
}: DocumentManagerProps) => {
  const [hubs, setHubs] = useState(initialHubs ?? [])
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

  const addHub = () => {
    const newHub = {
      categoryName: `Hub ${hubs.length + 1}`,
      documents: [{ documentName: 'Document Name', documentValue: 'Link' }],
    }

    const updatedHubs = [...hubs, newHub]
    setHubs(updatedHubs)
    setValue('documents', updatedHubs)
  }

  const removeHub = (index: number) => {
    const updatedHubs = hubs.filter((_, i) => i !== index)
    setHubs(updatedHubs)
    setValue('documents', updatedHubs)
  }

  const updateHubName = (index: number, newName: string) => {
    const updatedHubs = [...hubs]
    updatedHubs[index].categoryName = newName
    setHubs(updatedHubs)
  }

  const addDocument = (hubIndex: number) => {
    const newDocument = {
      documentName: 'New Document',
      documentValue: 'New Value',
    }
    const updatedHubs = [...hubs]
    updatedHubs[hubIndex].documents.push(newDocument)
    setHubs(updatedHubs)
  }

  const updateDocument = (
    hubIndex: number,
    docIndex: number,
    field: string,
    value: string,
  ) => {
    const updatedHubs = [...hubs]
    // @ts-ignore
    updatedHubs[hubIndex].documents[docIndex][field] = value
    setHubs(updatedHubs)
  }

  const removeDocument = (hubIndex: number, documentIndex: number) => {
    const updatedHubs = [...hubs]
    updatedHubs[hubIndex].documents.splice(documentIndex, 1)
    setHubs(updatedHubs)
  }
  const validateFields = () => {
    let valid = true
    const newErrors = {}
    hubs.forEach((hub, hIndex) => {
      if (!hub.categoryName) {
        // @ts-ignore
        newErrors[`hub-${hIndex}-categoryName`] = true
        valid = false
      } else {
        // @ts-ignore
        newErrors[`hub-${hIndex}-categoryName`] = false
      }

      hub.documents.forEach((doc, dIndex) => {
        if (!doc.documentName) {
          // @ts-ignore
          newErrors[`doc-${hIndex}-${dIndex}-name`] = true
          valid = false
        } else {
          // @ts-ignore
          newErrors[`doc-${hIndex}-${dIndex}-name`] = false
        }

        if (!doc.documentValue) {
          // @ts-ignore
          newErrors[`doc-${hIndex}-${dIndex}-value`] = true
          valid = false
        } else {
          // @ts-ignore
          newErrors[`doc-${hIndex}-${dIndex}-value`] = false
        }
      })
    })
    setErrors(newErrors)
    return valid
  }

  const handleOnBlur = () => {
    if (validateFields()) {
      setValue('documents', hubs)
    } else {
      setError('documents', { message: 'Please fill in all fields' })
    }
  }
  useEffect(() => {
    if (initialHubs && initialHubs?.length) {
      setHubs(initialHubs)
    }
  }, [initialHubs])

  return (
    <Flex direction="column" gap={2}>
      <Flex alignItems="center" gap={2}>
        <IconButton
          bg="transparent"
          aria-label="Add Contract"
          w="24px"
          h="24px"
          borderRadius="full"
          minW="24px"
          _hover={{ bg: colors.grey }}
          icon={<Plus color={colors.white} size={24} />}
          onClick={addHub}
        />
        <UserFormLabel m="0" htmlFor="contracts">
          Hub
        </UserFormLabel>
      </Flex>
      <Flex direction="column" gap={14}>
        {hubs.map((hub, hubIndex) => (
          <Flex key={hubIndex} direction="column" gap="10px" mt={6}>
            <Flex alignItems="flex-start" gap={2}>
              <UserInput
                placeholder="Hub name"
                value={hub.categoryName}
                isError={errors[`hub-${hubIndex}-categoryName`]}
                errorMessage="Category name is required"
                onChange={(e) => {
                  updateHubName(hubIndex, e.target.value)
                  setErrors((prev) => ({
                    ...prev,
                    [`hub-${hubIndex}-categoryName`]: false,
                  }))
                  clearErrors('documents')
                }}
                onBlur={handleOnBlur}
              />
              <IconButton
                icon={<X size={24} />}
                bg={colors.redPastel}
                color={colors.white}
                onClick={() => removeHub(hubIndex)}
                aria-label={''}
                _hover={{ bg: colors.redPastelLight }}
              />
            </Flex>
            {hub.documents.map((document, docIndex) => (
              <Flex key={docIndex} gap="10px">
                <UserInput
                  placeholder="Document name"
                  value={document.documentName}
                  isError={errors[`doc-${hubIndex}-${docIndex}-name`]}
                  errorMessage="Document name is required"
                  onChange={(e) => {
                    updateDocument(
                      hubIndex,
                      docIndex,
                      'documentName',
                      e.target.value,
                    )
                    setErrors((prev) => ({
                      ...prev,
                      [`doc-${hubIndex}-${docIndex}-name`]: false,
                    }))
                    clearErrors('documents')
                  }}
                  onBlur={handleOnBlur}
                />
                <UserInput
                  placeholder="Document value"
                  value={document.documentValue}
                  isError={errors[`doc-${hubIndex}-${docIndex}-value`]}
                  errorMessage="Document value is required"
                  onChange={(e) => {
                    updateDocument(
                      hubIndex,
                      docIndex,
                      'documentValue',
                      e.target.value,
                    )
                    setErrors((prev) => ({
                      ...prev,
                      [`doc-${hubIndex}-${docIndex}-value`]: false,
                    }))
                    clearErrors('documents')
                  }}
                  onBlur={handleOnBlur}
                />
                <IconButton
                  icon={<X size={24} />}
                  bg="transparent"
                  borderRadius="full"
                  color="red"
                  onClick={() => removeDocument(hubIndex, docIndex)}
                  aria-label="Remove Document"
                  _hover={{ bg: colors.greyBackground }}
                />
              </Flex>
            ))}
            <Button
              bg={colors.greyLight}
              color={colors.white}
              onClick={() => addDocument(hubIndex)}
              _hover={{ bg: colors.greyText }}
            >
              Add Document
            </Button>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default DocumentManager
