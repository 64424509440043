import { forwardRef } from 'react'
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react'
import { LucideIcon } from 'lucide-react'
import colors from '../../constants/colors'

interface UserInputProps extends InputProps {
  bg?: string
  icon?: LucideIcon
  iconColor?: string
  isError?: boolean
  errorMessage?: string
}

const UserInput = forwardRef<HTMLInputElement, UserInputProps>(
  (
    {
      icon: Icon,
      iconColor = colors.white,
      bg = colors.grey,
      justifyContent,
      isError = false,
      errorMessage = '',
      ...props
    },
    ref,
  ) => {
    return (
      <FormControl isInvalid={isError}>
        <InputGroup justifyContent={justifyContent}>
          {Icon && (
            <InputLeftElement pointerEvents="none">
              <Icon color={iconColor} strokeWidth={1} />
            </InputLeftElement>
          )}
          <Input
            {...props}
            bg={bg}
            ref={ref}
            border="none"
            color={colors.white}
            pointerEvents="auto"
            _hover={{ bg: bg }}
            _placeholder={{ color: colors.greyText }}
          />
        </InputGroup>
        {isError && (
          <FormErrorMessage>
            {errorMessage || 'This field is required'}
          </FormErrorMessage>
        )}
      </FormControl>
    )
  },
)

UserInput.displayName = 'UserInput'

export default UserInput
