import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import colors from '../../constants/colors'
import { IUser } from '../../types/types'

export const UserCard = (user: IUser) => {
  const { first_name, last_name, location, license, rates, contracts, avatar } =
    user

  return (
    <Flex
      bg={colors.greyBackground}
      color={colors.white}
      borderRadius="25px"
      pos="relative"
      w="100%"
      h="300px"
      maxW="350px"
      minW="280px"
    >
      <Flex
        flexDir="column"
        justifyContent="flex-start"
        alignItems="center"
        w="100%"
        py="25px"
        mt="50px"
      >
        <Box pos="absolute" top="-20%">
          <Avatar
            src={avatar ?? ''}
            w="120px"
            h="120px"
            bg={colors.greyExtraLight}
          />
        </Box>

        <Flex flexDir="column" alignItems="center" px="25px">
          <Heading size="md" isTruncated maxW="200px">
            {first_name} {last_name}
          </Heading>
          <Text>{location}</Text>
        </Flex>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mt="20px"
          px="25px"
        >
          <Flex flexDir="column" alignItems="start">
            <Heading size="md">{license}</Heading>
            <Text>License</Text>
          </Flex>
          <Flex flexDir="column" alignItems="end">
            <Heading size="md">{rates}</Heading>
            <Text>Rates</Text>
          </Flex>
        </Flex>

        <Divider my="15px" borderColor={colors.greyLight} />
        <Flex flexDir="column" alignItems="start" w="100%" px="25px">
          <Heading fontSize="14px">Validated Contracts:</Heading>

          {contracts && contracts.length > 0 && (
            <Tooltip
              hasArrow
              label={contracts.map((c) => c.name).join('\n')}
              bg="gray.300"
              color="black"
              sx={{ whiteSpace: 'pre-line' }}
            >
              <Box
                w="100%"
                color={colors.greyLight}
                display="-webkit-box"
                overflow="hidden"
                whiteSpace="pre" // CSS property to respect newlines in text
                style={{
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2, // Limit text to two lines
                }}
              >
                {contracts.slice(0, 2).map((c, i) => (
                  <Text key={i} isTruncated>
                    {c.name}
                  </Text>
                ))}
              </Box>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
