import { useState } from 'react'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { LoginForm } from '../components/Login/LoginForm'
import { useNavigate } from 'react-router-dom'
import { login } from '../lib/firebase/actions'
import colors from '../constants/colors'

interface ISignInArgs {
  email: string
  password: string
}

export default function SignIn() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const signIn = async ({ email, password }: ISignInArgs) => {
    setLoading(true)

    try {
      const user = await login(email, password)
      if (!user) {
        navigate('/login')
        console.error('No user returned')
      } else {
        navigate('/')
      }
    } catch (error) {
      console.error('Authentication error', error)
      toast({
        title: 'Login Error',
        description: 'Invalid email or password. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex
      h="100vh"
      w="100vw"
      flexDir={'column'}
      pos="relative"
      justifyContent="center"
      alignItems="center"
      p={'20px'}
    >
      <Text
        pos="absolute"
        as={'span'}
        top={0}
        left={0}
        pl="20px"
        fontSize={{ md: '45px' }}
        display={{ base: 'none', md: 'block' }}
        color={colors.greyLight}
      >
        STOREE Partners
      </Text>
      <Flex
        w={{ base: '80vw', md: '50vw', lg: '50vw', xl: '40vw' }}
        maxW="500px"
        justifySelf={'center'}
      >
        <LoginForm signIn={signIn} loading={loading} />
      </Flex>
    </Flex>
  )
}
