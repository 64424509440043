import { Box, Flex } from '@chakra-ui/react'
import colors from '../../constants/colors'
import { Sidebar } from '../Sidebar'
import Header from '../Header'
import { Outlet } from 'react-router-dom'

export default function LayoutWithSidebar() {
  return (
    <Flex flexDir={{ base: 'column', lg: 'row' }}>
      <Box display={{ base: 'none', md: 'none', lg: 'flex' }}>
        <Sidebar />
      </Box>
      <Box
        bg={colors.greyDark}
        w={'100%'}
        h={'100dvh'}
        flexDir={{ base: 'column', md: 'row' }}
        overflowY="auto"
      >
        <Box display={{ base: 'block', md: 'flex', lg: 'none' }}>
          <Header />
        </Box>
        <Outlet />
      </Box>
    </Flex>
  )
}
