import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}
export default function MobileMenu({ isOpen, onClose, children }: Props) {
  const placement = useBreakpointValue<'top' | 'right' | 'bottom' | 'left'>({
    base: 'top',
    md: 'left',
    lg: 'left',
  })

  return (
    <Drawer isOpen={isOpen} placement={placement} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  )
}
