import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Contract } from '../../types/types'
import { generateDoughnutData, getColorByIndex } from './utils'

ChartJS.register(ArcElement, Tooltip, Legend)

const DonutChartWithDetails = ({ contracts }: { contracts: Contract[] }) => {
  const data = generateDoughnutData(contracts)

  return (
    <Flex
      mt="40px"
      direction="column-reverse"
      alignItems="center"
      gap={4}
      p="20px"
      justifyContent="center"
    >
      <Box
        w={{ base: '150px', '2xl': '250px' }}
        h={{ base: '150px', '2xl': '250px' }}
      >
        <Doughnut
          data={data}
          options={{ plugins: { legend: { display: false } } }}
        />
      </Box>
      <Flex width="100%" justifyContent="space-between">
        {contracts.map((contract, index) => {
          const total = Object.values(contract.value).reduce(
            (sum, value) => sum + parseFloat(value || '0'),
            0,
          )
          const percentage = (
            (total / data.datasets[0].data.reduce((sum, val) => sum + val, 0)) *
            100
          ).toFixed(0)
          const color = getColorByIndex(index)

          return (
            <Flex
              key={index}
              justifyContent="space-between"
              alignItems="center"
              bg="#2a2a2a"
              gap={2}
              fontSize="14px"
              p="5px"
              borderRadius="8px"
            >
              <Flex alignItems="center" gap={2}>
                <Box width="10px" height="10px" bg={color} borderRadius="50%" />
                <Text color="white" maxW={'150px'} isTruncated>
                  {contract.name}
                </Text>
              </Flex>
              <Text color="white">{percentage}%</Text>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default DonutChartWithDetails
