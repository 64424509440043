import { Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { Menu } from 'lucide-react'
import { Sidebar } from '../Sidebar'
import MobileMenu from './MobileMenu'
import colors from '../../constants/colors'

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      display={{ base: 'flex', md: 'flex', lg: 'none' }}
      alignItems="center"
      justifyContent="space-between"
      bg={'#2E2E2E'}
      w="100%"
      p={4}
    >
      <Text fontSize="20px" color={colors.greyLight}>
        Storee Partners
      </Text>
      <IconButton aria-label="Menu" icon={<Menu />} onClick={onOpen} />
      {isOpen && (
        <MobileMenu isOpen={isOpen} onClose={onClose}>
          <Sidebar />
        </MobileMenu>
      )}
    </Flex>
  )
}
