import useSWR from 'swr'
import {
  deleteUserByUID,
  getAllUsers,
  getDocuments,
  getUserAndChildrenData,
  getUserByID,
} from '../lib/firebase/api'
import useSWRMutation from 'swr/mutation'

export const useDocuments = (userId: string) => {
  const { data, error, isLoading } = useSWR(
    userId ? `documents/${userId}` : null,
    () => getDocuments(userId),
  )

  return {
    documents: data,
    isLoading: isLoading && !error && !data,
    isError: error,
  }
}

export const useUserData = (userId: string) => {
  const { data, error, isLoading } = useSWR(
    userId ? `user/${userId}` : null,
    () => getUserByID(userId),
  )

  return {
    user: data,
    isLoading: isLoading && !error && !data,
    isError: error,
  }
}

export const useUserAndChildrenData = (userId: string) => {
  const { data, error, isLoading } = useSWR(
    userId ? `userAndChildren/${userId}` : null,
    async () => await getUserAndChildrenData(userId),
  )

  return {
    userDataAndChildren: data,
    isLoading: isLoading && !error && !data,
    isError: error,
  }
}

export const useAllUsers = () => {
  const { data, error, isLoading } = useSWR('allUsers', getAllUsers)

  return {
    users: data,
    isLoading: isLoading && !error && !data,
    isError: error,
  }
}

export const useDeleteUser = () => {
  const { trigger, data, error, isMutating } = useSWRMutation(
    'deleteUser',
    async (key: string, { arg }: { arg: string }) => {
      await deleteUserByUID(arg)
    },
  )

  return {
    deleteUser: trigger,
    data,
    isLoading: isMutating,
    error,
  }
}
