import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore'

import { IHub, IUser, UserFormData } from '../../types/types'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  getAuth,
} from 'firebase/auth'

import { db, secondaryAuth } from './config'

export async function getDocuments(
  userId: string,
): Promise<IHub[] | undefined> {
  try {
    const documentRef = doc(db, 'documents', userId)
    const documentSnapshot = await getDoc(documentRef)

    if (documentSnapshot.exists()) {
      const documentData = documentSnapshot.data() as IHub[]
      // @ts-ignore
      return documentData['hub']
    } else {
      console.log('No document found for the given userId.')
      return undefined
    }
  } catch (error) {
    console.error('Error getting user document:', error)
    return undefined
  }
}
export const updateUserDocuments = async (
  userId: string,
  documents: IHub[],
) => {
  const userDocsRef = doc(collection(db, 'documents'), userId)
  try {
    await setDoc(userDocsRef, { hub: documents })
    console.log('Documents updated successfully')
  } catch (error) {
    console.error('Error updating user documents:', error)
    throw error
  }
}

export async function getUserByID(userId: string) {
  try {
    const userRef = doc(db, 'users', userId)
    const docSnapshot = await getDoc(userRef)

    if (!docSnapshot.exists()) {
      console.log('No such user!')
      return null
    } else {
      return docSnapshot.data()
    }
  } catch (error) {
    console.error('Error getting user data:', error)
    return null
  }
}

export async function getUserAndChildrenData(userId: string) {
  try {
    const userData = (await getUserByID(userId)) as IUser
    if (!userData) return null

    const children = userData.children || []
    if (children.length === 0) {
      return [userData]
    }

    const q = query(collection(db, 'users'), where('__name__', 'in', children))
    const childrenSnapshot = await getDocs(q)
    const childrenData = childrenSnapshot.docs.map((doc) =>
      doc.data(),
    ) as IUser[]

    return [userData, ...childrenData]
  } catch (error) {
    console.error('Error getting user and children data:', error)
    return null
  }
}

export const getAllUsers = async () => {
  try {
    const usersSnapshot = await getDocs(collection(db, 'users'))
    const users = usersSnapshot.docs.map((doc) => doc.data()) as IUser[]
    return users
  } catch (error) {
    console.error('Error getting users:', error)
    return null
  }
}

export const addUser = async (user: UserFormData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      secondaryAuth,
      user.email,
      user.password!,
    )
    const newUser = userCredential.user

    await updateProfile(newUser, {
      displayName: `${user.first_name} ${user.last_name}`,
    })

    const userRef = doc(db, 'users', newUser.uid)
    await setDoc(userRef, {
      uid: newUser.uid,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      location: user.location,
      license: user.license,
      parent: user.parent || null,
      rates: user.rates,
      avatar: user.avatar ?? '',
      contracts: user.contracts ?? [],
    })

    console.log('New user created successfully:', newUser.uid)
    return newUser
  } catch (error) {
    console.error('Error creating user:', error)
    throw error
  }
}

export const deleteUserByUID = async (userId: string) => {
  try {
    const auth = getAuth()
    const currentUser = auth.currentUser

    // const functions = getFunctions()
    // const deleteUser = httpsCallable(functions, 'deleteUser')

    if (currentUser && currentUser.uid === userId) {
      throw new Error('You cannot delete your own account.')
    }
    // console.log('deleteUser', deleteUser)

    // const res = await deleteUser({ userId })
    // console.log(res)

    const userRef = doc(db, 'users', userId)
    await deleteDoc(userRef)

    console.log('User data deleted successfully')
  } catch (error) {
    console.error('Error deleting user:', error)
    throw new Error('Failed to delete user')
  }
}

export const updateUser = async (userId: string, userData: Partial<IUser>) => {
  try {
    const userRef = doc(db, 'users', userId)
    await updateDoc(userRef, userData)
    console.log('User data updated successfully')
  } catch (error) {
    console.error('Error updating user data:', error)
    throw new Error('Failed to update user')
  }
}
