import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
} from '@chakra-ui/react'
import colors from '../../../constants/colors'

interface BaseModalProps extends Omit<ModalProps, 'children'> {
  header?: React.ReactNode
  body?: React.ReactNode
  footer?: React.ReactNode
}

export const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  onClose,
  header,
  body,
  footer,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest} isCentered>
      <ModalOverlay />
      <ModalContent bg={colors.greyBackground} mx="10px">
        <ModalHeader textAlign="center" color={colors.white}>
          {header}
        </ModalHeader>
        <ModalCloseButton color={colors.white} />
        <ModalBody maxH="500px" overflowY="auto">
          {body}
        </ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  )
}
