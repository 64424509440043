import { Flex, Text } from '@chakra-ui/react'
import colors from '../constants/colors'
export default function NotFound() {
  return (
    <Flex h="100vh" flexDir="column" justifyContent="center">
      <Text color={colors.greyExtraLight} fontSize="x-large" textAlign="center">
        404
      </Text>
      <Text color={colors.greyExtraLight} fontSize="x-large" textAlign="center">
        Page Not Found
      </Text>
    </Flex>
  )
}
