import { Flex, IconButton, Text } from '@chakra-ui/react'
import { ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import colors from '../../constants/colors'

interface Props {
  title?: string
}
export const BackButton = ({ title }: Props) => {
  const navigate = useNavigate()
  return (
    <Flex pos="absolute" top="20px" left="20px" alignItems="center" gap={2}>
      <IconButton
        borderRadius={'full'}
        icon={<ArrowLeft />}
        onClick={() => navigate(-1)}
        aria-label={''}
      />
      <Text color={colors.white}>{title}</Text>
    </Flex>
  )
}
