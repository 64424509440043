import { Button, Flex, Text } from '@chakra-ui/react'
import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import routs from '../../constants/routes'

export const TableHeader = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(routs.ADD_USER)
  }
  return (
    <Flex w="100%" justifyContent="flex-start">
      <Button bg="white" onClick={handleClick}>
        <Plus />
        <Text>Add User</Text>
      </Button>
    </Flex>
  )
}
