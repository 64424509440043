import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from './config'

export async function login(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    )
    return userCredential.user
  } catch (error) {
    console.error('Login error:', error)
    throw error
  }
}

export async function logout() {
  try {
    await signOut(auth)
  } catch (error) {
    console.error('Logout error:', error)
    throw error
  }
}
