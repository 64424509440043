const colors = {
  white: '#FFFFFF',
  greyDark: '#212121',
  greyExtraDark: '#171717',
  greyExtraLight: '#9c9c9e',
  grey: '#2F2F2F',
  greyText: '#4A5568',
  greyLight: '#696969',
  blackLight: '#1E2021',
  greyBackground: '#37383D',
  black: '#000000',
  primary: '#F9F9F9',
  secondary: '#F0F0F0',
  greenPastele: '#35b360',
  greenLight: '#2ECC71',
  lightPink: '#FFB6C1',
  lightPeach: '#FFDFBA',
  lightYellow: '#FFEFBA',
  lightGreen: '#B8FFBA',
  lightBlue: '#BAE0FF',
  lightLavender: '#BAA5FF',
  lightRose: '#FFBACB',
  lightCoral: '#FFBACB',
  lightAqua: '#BAFFFA',
  lightPinkishPurple: '#FFBACB',
  redPastel: '#e15454',
  redPastelLight: '#f88c8c',
}

export default colors
