import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import colors from '../../../constants/colors'

export const CardsWrapper = styled(Flex)`
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1278px) {
    justify-content: space-between;
  }

  @media (min-width: 1350px) and (max-width: 1600px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (min-width: 1600px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`
export const CustomDivider = styled(Box)`
  width: 100%;
  height: 4px;
  background-color: ${colors.grey};

  @media (max-width: 1278px) {
    width: 4px;
    height: 100px;
  }
`
