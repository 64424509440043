import { Flex } from '@chakra-ui/react'
import React from 'react'
import colors from '../../constants/colors'
interface Props {
  children: React.ReactNode
}

export default function LayoutWithoutSideBar({ children }: Props) {
  return (
    <Flex
      bg={colors.greyBackground}
      w={'100%'}
      h={'100vh'}
      flexDir={{ base: 'column', md: 'row' }}
      overflowY="auto"
    >
      {children}
    </Flex>
  )
}
