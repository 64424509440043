import React, { memo } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { UserCard } from './UserCard'
import { CardsWrapper, CustomDivider } from './ui/styled.components'
import colors from '../../constants/colors'
import { IUser } from '../../types/types'

const mockData = {
  uid: '',
  first_name: '',
  last_name: '',
  location: '',
  license: '',
  rates: '',
  contracts: [],
  children: [],
  email: '',
  parent: '',
  avatar: '',
}
interface OverviewProps {
  data: IUser[]
}
export const UserCards = memo(({ data }: OverviewProps) => {
  const currentUserData = data[0]
  const usersChildrenData = data?.slice(1)
  const renderUserCards = () => {
    if (usersChildrenData && usersChildrenData.length >= 3) {
      return usersChildrenData.map((childData, index) => (
        <React.Fragment key={index}>
          <UserCard {...childData} />
          {index < usersChildrenData.length - 1 && <CustomDivider />}
        </React.Fragment>
      ))
    } else if (usersChildrenData && usersChildrenData.length === 2) {
      return (
        <>
          {usersChildrenData.map((childData, index) => (
            <React.Fragment key={index}>
              <UserCard {...childData} />
              {index < usersChildrenData.length - 1 && <CustomDivider />}
            </React.Fragment>
          ))}
          <CustomDivider />
          <UserCard {...mockData} />
        </>
      )
    } else if (usersChildrenData && usersChildrenData.length === 1) {
      return (
        <>
          <UserCard {...usersChildrenData[0]} />
          <CustomDivider />
          <UserCard {...mockData} />
          <CustomDivider />
          <UserCard {...mockData} />
        </>
      )
    } else {
      return (
        <>
          <UserCard {...mockData} />
          <CustomDivider />
          <UserCard {...mockData} />
          <CustomDivider />
          <UserCard {...mockData} />
        </>
      )
    }
  }
  return (
    <CardsWrapper>
      {currentUserData && (
        <Flex
          w="100%"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <UserCard {...currentUserData} />
          <Box
            height={{ base: '100px', xl: '130px' }}
            w="5px"
            bg={colors.grey}
          />
        </Flex>
      )}
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        flexDir={{ base: 'column', xl: 'row' }}
      >
        {renderUserCards()}
      </Flex>
    </CardsWrapper>
  )
})

UserCards.displayName = 'UserCards'
