import { Box, Spinner } from '@chakra-ui/react'
import useAuth from '../hooks/useAuth'
import { useDocuments } from '../hooks/useSwr'
import colors from '../constants/colors'
import { DocumnetsList } from '../components/Hub'

export default function Hub() {
  const { user } = useAuth()
  const { documents: data, isLoading } = useDocuments(user?.uid!)
  return (
    <>
      {isLoading ? (
        <Spinner
          color={colors.greyLight}
          w="80px"
          h="80px"
          position="absolute"
          fontWeight="bold"
          top="50%"
          left="55%"
        />
      ) : (
        <Box w="100%" height="100%" pt="100px" px="5%" h="max-content">
          {data && <DocumnetsList documents={data} />}
        </Box>
      )}
    </>
  )
}
