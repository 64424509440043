import { Box, Flex, Text } from '@chakra-ui/react'
import AnimatedChart from '../components/Earnings/AnimatedChart'
import useAuth from '../hooks/useAuth'
import { useUserAndChildrenData } from '../hooks/useSwr'
import DonutChartWithDetails from '../components/Earnings/DonutChart'

export default function Earnings() {
  const { user } = useAuth()
  const { userDataAndChildren: data } = useUserAndChildrenData(user?.uid!)
  const contracts = data?.[0].contracts ?? []
  const liveContracts = contracts.filter((contract) => {
    const values = contract.value
    return Object.values(values).some((monthValue) => monthValue !== '')
  })
  const calculateTotalContractValues = contracts.reduce(
    (total, contract) =>
      total +
      Object.values(contract.value).reduce(
        (sum, value) => sum + parseFloat(value) || 0,
        0,
      ),
    0,
  )

  return (
    <Flex
      h="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDir="column"
      px="40px"
      overflowX="auto"
    >
      <Flex
        minW="1000px"
        w="100%"
        flexDir="column"
        pt="100px"
        pb="50px"
        justifyContent="center"
      >
        <Box h={{ base: '300px', md: '400px', lg: '450px', '2xl': '100%' }}>
          <Flex
            w="100%"
            color="white"
            justifyContent="space-between"
            fontSize="18px"
            gap={6}
            mb="20px"
          >
            <Text>Earnings {calculateTotalContractValues}</Text>
            <Text>Validated Contracts {contracts.length}</Text>
            <Text>Live Contracts {liveContracts.length}</Text>
            <Text>Direct Deals {liveContracts.length}</Text>
          </Flex>
          <AnimatedChart contracts={contracts} />
        </Box>
        <DonutChartWithDetails contracts={contracts} />
      </Flex>
    </Flex>
  )
}
