import {
  Button,
  Input,
  Text,
  FormControl,
  useToast,
  Flex,
} from '@chakra-ui/react'
import { useState } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../lib/firebase/config'
import colors from '../../constants/colors'
import { BaseModal } from '../commonComponents/BaseModal'

interface ForgotPasswordModalProps {
  isOpen: boolean
  onClose: () => void
}

export const ForgotPasswordModal = ({
  isOpen,
  onClose,
}: ForgotPasswordModalProps) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const handlePasswordReset = async () => {
    setLoading(true)
    try {
      await sendPasswordResetEmail(auth, email)
      toast({
        title: 'Password Reset Email Sent',
        description: `An email has been sent to ${email}.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to send password reset email.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      header={<Text>Reset Password</Text>}
      body={
        <FormControl>
          <Text color={colors.white} mb={4}>
            Enter your email address to reset your password:
          </Text>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            color={colors.greyLight}
            h="40px"
            px="0px"
            fontSize="14px"
            fontWeight="bold"
            border="none"
            w="100%"
            bg="transparent"
            _placeholder={{ color: colors.greyLight, fontWeight: 'bold' }}
            _focusVisible={{
              boxShadow: 'none',
              fontsize: '14px',
            }}
          />
        </FormControl>
      }
      footer={
        <Flex>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handlePasswordReset}
            isLoading={loading}
          >
            Reset Password
          </Button>
          <Button
            variant="ghost"
            onClick={onClose}
            color={colors.white}
            _hover={{ bg: colors.white, color: colors.black }}
          >
            Cancel
          </Button>
        </Flex>
      }
    />
  )
}
