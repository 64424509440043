const routs = {
  OVERVIEW: '/',
  LOGIN: '/login',
  HUB: '/hub',
  ADMIN: '/admin',
  ADD_USER: '/admin/add-user',
  EDIT_USER: '/admin/edit-user/:id',
  EARNINGS: '/earnings',
  NOT_FOUND: '/404',
}

export default routs
