import { Flex, Text } from '@chakra-ui/react'
import { IHub } from '../../types/types'
import { DocumentCard } from './DocumentCard'
import colors from '../../constants/colors'

export const DocumnetsList = ({ documents }: { documents?: IHub[] }) => {
  if (!documents) {
    return <Text>Documents not found</Text>
  }

  return (
    <Flex
      flexDir="column"
      m="0 auto"
      w={{ base: '100%', md: '70%', lg: '50%' }}
    >
      {documents.map(({ categoryName, documents }, index) => (
        <Flex flexDir="column" key={index} gap={4} mb="40px">
          <Text fontSize="xl" fontWeight="bold" color={colors.white}>
            {categoryName}
          </Text>
          {documents.map((doc, index) => (
            <DocumentCard key={index} document={doc} />
          ))}
        </Flex>
      ))}
    </Flex>
  )
}
