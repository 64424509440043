import colors from '../../constants/colors'
import { Contract } from '../../types/types'
import { monthOrder } from '../UserForm/ContractInput'

export const generateChartOptions = ({
  legendBoxWidth,
  legendBoxHeight,
  lineWidth,
  pointRadius,
}: {
  legendBoxWidth: number | undefined
  legendBoxHeight: number | undefined
  lineWidth: number | undefined
  pointRadius: number | undefined
}) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 14,
            weight: 400 as const,
          },
          color: 'white',
          boxWidth: legendBoxWidth || 80,
          boxHeight: legendBoxHeight || 40,
          padding: 10,
        },
      },
      tooltip: {
        titleFont: {
          size: 18,
          weight: 400 as const,
        },
        bodyFont: {
          size: 16,
        },
        padding: 20,
        boxPadding: 10,
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
          font: {
            weight: 400 as const,
          },
        },
      },
      y: {
        ticks: {
          color: 'white',
          font: {
            weight: 400 as const,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: lineWidth || 4,
      },
      point: {
        radius: pointRadius || 6,
      },
    },
    animation: {
      duration: 1000,
    },
    layout: {
      padding: 20,
    },
  }
}
export const pastelColors = [
  colors.lightAqua,
  colors.lightBlue,
  colors.lightGreen,
  colors.lightCoral,
  colors.lightPink,
  colors.lightYellow,
  colors.lightRose,
  colors.lightPeach,
  colors.lightLavender,
  colors.lightPinkishPurple,
]

export const getColorByIndex = (index: number) => {
  return pastelColors[index % pastelColors.length]
}
export const generateChartData = (contracts: Contract[]) => {
  const labels = monthOrder.map(
    (month) => month.charAt(0).toUpperCase() + month.slice(1),
  )

  const datasets = contracts.map((contract, index) => ({
    label: contract.name,
    data: monthOrder.map((month) => parseFloat(contract.value[month] || '0')),
    borderColor: getColorByIndex(index),
    backgroundColor: getColorByIndex(index),
    pointRadius: 8,
    pointHoverRadius: 10,
    lineTension: 0.3,
  }))

  return { labels, datasets }
}

export const generateDoughnutData = (contracts: Contract[]) => {
  const labels = contracts.map((contract) => contract.name)
  const data = contracts.map((contract) => {
    const total = Object.values(contract.value).reduce(
      (sum, value) => sum + parseFloat(value || '0'),
      0,
    )
    return total
  })

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: labels.map((_, index) => getColorByIndex(index)),
        borderColor: labels.map((_, index) => getColorByIndex(index)),
        borderWidth: 1,
      },
    ],
  }
}
