import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import routes from '../../../constants/routes'

const PrivateAuthRoute = () => {
  const { user, loading } = useAuth()

  if (!user && !loading) {
    return <Navigate to={routes.LOGIN} />
  }
  return <Outlet />
}

export default PrivateAuthRoute
