import { Route, Routes } from 'react-router-dom'
import routes from './constants/routes'
import Login from './pages/Login'
import LayoutWithoutSideBar from './components/Layouts/LayoutWithousSideBar'
import PrivateAuthRoute from './components/commonComponents/PrivateRoutes/PrivateAuthRoute'
import Overview from './pages/Overview'
import LayoutWithSidebar from './components/Layouts/LayoutWithSidebar'
import Admin from './pages/Admin'
import AddUser from './pages/AddUser'
import EditUser from './pages/EditUser'
import PrivateAdminRoute from './components/commonComponents/PrivateRoutes/PrivateAdminRoute'
import NotFound from './pages/NotFound'
import Earnings from './pages/Earnings'
import Hub from './pages/Hub'

function App() {
  return (
    <Routes>
      <Route
        path={routes.LOGIN}
        element={
          <LayoutWithoutSideBar>
            <Login />
          </LayoutWithoutSideBar>
        }
      />
      <Route path="/" element={<PrivateAuthRoute />}>
        <Route element={<LayoutWithSidebar />}>
          <Route index element={<Overview />} />
          <Route path={routes.OVERVIEW} element={<Overview />} />
          <Route path={routes.HUB} element={<Hub />} />
          <Route path={routes.EARNINGS} element={<Earnings />} />
          <Route element={<PrivateAdminRoute />}>
            <Route path={routes.ADMIN} element={<Admin />} />
            <Route path={routes.ADD_USER} element={<AddUser />} />
            <Route path={routes.EDIT_USER} element={<EditUser />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
