import {
  Button,
  Flex,
  Input,
  Image,
  FormControl,
  Divider,
  Box,
  useDisclosure,
  Text,
  Link,
} from '@chakra-ui/react'
import { useState } from 'react'
import colors from '../../constants/colors'
import { ChevronRight } from 'lucide-react'
import { ForgotPasswordModal } from './ForgotPasswordModal'

interface LoginFormProps {
  signIn: ({ email, password }: { email: string; password: string }) => void
  loading: boolean
}

export const LoginForm = ({ signIn, loading }: LoginFormProps) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    signIn({ email, password })
  }

  return (
    <Flex flexDir={'column'} alignItems="center" w="100%">
      <Image
        src="storee-logo.svg"
        alt="logo"
        width={120}
        height={120}
        style={{ marginBottom: '50px' }}
        color="white"
      />
      <Flex
        as="form"
        onSubmit={handleSubmit}
        flexDir={'column'}
        background={`${colors.blackLight} !important`}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        w="100%"
        p="25px"
        borderRadius={'20px'}
        backdropFilter="blur(10px)"
        boxShadow={'0 0 50px -30px black'}
      >
        <FormControl position="relative">
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            h="40px"
            fontWeight="bold"
            color={colors.greyLight}
            background={colors.blackLight}
            fontSize="14px"
            border="none"
            bg="transparent"
            w="100%"
            _placeholder={{ color: colors.greyLight, fontWeight: 'bold' }}
            _focusVisible={{
              boxShadow: 'none',
              fontsize: '14px',
            }}
          />
          <Button
            type="submit"
            position="absolute"
            bg={colors.blackLight}
            zIndex={50}
            border={`5px solid ${colors.greyBackground}`}
            color={colors.greyBackground}
            borderRadius="full"
            w="70px"
            h="70px"
            top="5px"
            right="-50px"
            p="5px"
            isLoading={loading}
            fontSize="16px"
            _hover={{
              bg: colors.blackLight,
            }}
            _loading={{
              color: 'white',
              bg: colors.blackLight,
              opacity: 1,
              cursor: 'not-allowed',
            }}
            _disabled={{
              opacity: 1,
            }}
          >
            <ChevronRight color={colors.greyLight} />
          </Button>
        </FormControl>
        <Box px="15px" w="100%">
          <Divider
            borderBottomWidth="3px"
            borderColor={colors.greyBackground}
          />
        </Box>

        <FormControl>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            color={colors.greyLight}
            h="40px"
            fontSize="14px"
            fontWeight="bold"
            border="none"
            w="100%"
            bg="transparent"
            autoComplete="new-password"
            _placeholder={{ color: colors.greyLight, fontWeight: 'bold' }}
            _focusVisible={{
              boxShadow: 'none',
              fontsize: '14px',
            }}
          />
        </FormControl>
      </Flex>
      <Text mt={4} color="white">
        <Link onClick={onOpen}>Forgot Password?</Link>
      </Text>
      <ForgotPasswordModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
