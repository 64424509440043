import { Avatar, Flex, Text } from '@chakra-ui/react'
import { Row } from 'react-table'
import { IUser } from '../../types/types'

export const UserInfoCell = ({ row }: { row: Row<IUser> }) => {
  return (
    <Flex alignItems="center" gap={2}>
      <Avatar src={row.original?.avatar ?? ''} w="80px" h="80px" />
      <Text>{row.original.first_name}</Text>
      <Text>{row.original.last_name}</Text>
    </Flex>
  )
}
