import { Box, useToast } from '@chakra-ui/react'
import UserForm, { UserFormData } from '../components/UserForm'
import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { addUser, updateUserDocuments } from '../lib/firebase/api'
import { useNavigate } from 'react-router-dom'
import routs from '../constants/routes'
import { mutate } from 'swr'
import { BackButton } from '../components/commonComponents/BackButton'

export default function AddUser() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    setLoading(true)
    try {
      const { documents, ...dataWithoutDocuments } = data
      const user = await addUser(dataWithoutDocuments)
      await mutate('allUsers')
      if (user && user.uid) {
        await updateUserDocuments(user.uid, documents ?? [])
        navigate(routs.ADMIN)
      }
    } catch (error) {
      console.error('Error saving user data:', error)
      toast({
        title: 'Error',
        description: `Failed to save user data: ${error || 'Unknown error'}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box pt="100px" px="20px" pb="50px" pos="relative">
      <BackButton title="Back to Users Table" />
      <UserForm onSubmit={onSubmit} loading={loading} />
    </Box>
  )
}
