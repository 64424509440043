import { useForm, SubmitHandler } from 'react-hook-form'
import {
  Box,
  Button,
  FormControl,
  Stack,
  VStack,
  useDisclosure,
  Text,
  Tag,
  IconButton,
  Flex,
  Avatar,
  Divider,
} from '@chakra-ui/react'

import {
  Blocks,
  KeyRoundIcon,
  Mail,
  MapPinIcon,
  Percent,
  Plus,
  User2,
} from 'lucide-react'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { UserFormLabel } from '../commonComponents/UserFormLabel'
import UserInput from './UserInput'
import { AvatarUpload } from './AvatarUpload'
import { BaseModal } from '../commonComponents/BaseModal'
import { Contract, IHub, IUser } from '../../types/types'
import colors from '../../constants/colors'
import { ModalBodyContent } from './ModalBodyContent'
import { ModalTitle } from './ModalTitileContent'
import { useAllUsers } from '../../hooks/useSwr'
import { ContractInput } from './ContractInput'
import DocumentManager from './DocumentInputGroup'

export interface UserFormData {
  uid: string
  password?: string
  children: string[]
  contracts: Contract[]
  documents?: IHub[]
  email: string
  license: string
  location: string
  first_name: string
  last_name: string
  parent: string | null
  rates: string
  avatar?: string
}
interface UserFormProps {
  user?: IUser
  documents?: IHub[]
  onSubmit: SubmitHandler<UserFormData>
  loading: boolean
}

const UserForm: React.FC<UserFormProps> = ({
  user,
  documents,
  onSubmit,
  loading,
}) => {
  const { users } = useAllUsers()

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<UserFormData>()

  const [checkedParentUID, setParentUID] = useState(user?.parent ?? '')

  const [checkedChildrenUIDs, setChildrenUIDs] = useState<string[]>(
    user?.children ?? [],
  )

  const {
    isOpen: isParentModalOpen,
    onOpen: onParentModalOpen,
    onClose: onParentModalClose,
  } = useDisclosure()

  const {
    isOpen: isChildrenModalOpen,
    onOpen: onChildrenModalOpen,
    onClose: onChildrenModalClose,
  } = useDisclosure()

  const parentUID = watch('parent')
  const childrenUIDs = watch('children')
  const avatar = watch('avatar')

  const parent = useMemo(() => {
    const parentUser = users?.find((user: IUser) => user.uid === parentUID)
    return parentUser
  }, [parentUID, users])

  const children = useMemo(() => {
    const childrenUsers = users?.filter((user: IUser) =>
      childrenUIDs?.includes(user.uid),
    )
    return childrenUsers
  }, [childrenUIDs, users])

  const setAvatar = (url: string) => {
    setValue('avatar', url)
  }

  const handleParentSubmit = () => {
    setValue('parent', checkedParentUID)
    onParentModalClose()
  }
  const handleChildrenSelect = () => {
    setValue('children', checkedChildrenUIDs)
    onChildrenModalClose()
  }
  const potentialParentUIDs = useMemo(() => {
    return users?.filter((user: IUser) => !childrenUIDs?.includes(user.uid))
  }, [childrenUIDs, users])

  const potentialChildrenUIDs = useMemo(() => {
    return users?.filter(
      (user: IUser) => !parentUID || user.parent === parentUID,
    )
  }, [parentUID, users])

  useEffect(() => {
    if (user) {
      setValue('email', user.email)
      setValue('first_name', user.first_name)
      setValue('last_name', user.last_name ?? '')
      setValue('location', user.location)
      setValue('license', user.license)
      setValue('rates', user.rates)
      setValue('parent', user.parent ?? null)
      setValue('children', user.children ?? [])
      setValue('avatar', user.avatar)
      setValue('contracts', user.contracts ?? [])
    }
    if (documents && documents.length > 0) {
      setValue('documents', documents)
    }
  }, [user, setValue, documents])

  const handleFormSubmit: SubmitHandler<UserFormData> = (data) => {
    if (user) {
      const { password, ...dataWithoutPassword } = data
      onSubmit(dataWithoutPassword)
    } else {
      onSubmit(data)
    }
  }
  const isSubmitDisabled = Object.values(errors).length > 0

  return (
    <Box maxW={{ base: '400px', md: '1000px' }} mx="auto">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <VStack spacing={8} w="100%">
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="100%">
            <FormControl isInvalid={!!errors.email}>
              <UserFormLabel htmlFor="email">Email</UserFormLabel>
              <UserInput
                id="email"
                type="email"
                isDisabled={!!user}
                {...register('email', { required: true })}
                placeholder="Email address"
                icon={Mail}
              />
            </FormControl>

            <FormControl isInvalid={user ? false : !!errors.password}>
              <UserFormLabel htmlFor="password">Password</UserFormLabel>
              <UserInput
                id="password"
                type="password"
                isDisabled={!!user}
                autoComplete="new-password"
                {...register('password', { required: user ? false : true })}
                placeholder="Password"
                icon={KeyRoundIcon}
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="100%">
            <FormControl isInvalid={!!errors.first_name}>
              <UserFormLabel htmlFor="first_name">First Name</UserFormLabel>
              <UserInput
                id="first_name"
                {...register('first_name', { required: true })}
                placeholder="First Name"
                icon={User2}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.last_name}>
              <UserFormLabel htmlFor="last_name">Last Name</UserFormLabel>
              <UserInput
                id="last_name"
                {...register('last_name', { required: true })}
                placeholder="Last Name"
                icon={User2}
              />
            </FormControl>
          </Stack>

          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="100%">
            <FormControl isInvalid={!!errors.location}>
              <UserFormLabel htmlFor="location">Location</UserFormLabel>
              <UserInput
                id="location"
                {...register('location', { required: true })}
                placeholder="Location"
                icon={MapPinIcon}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.license}>
              <UserFormLabel htmlFor="license">License</UserFormLabel>
              <UserInput
                id="license"
                {...register('license', { required: true })}
                placeholder="License"
                icon={Blocks}
              />
            </FormControl>
            <FormControl isInvalid={!!errors.rates}>
              <UserFormLabel htmlFor="rates">Rates</UserFormLabel>
              <UserInput
                id="rates"
                {...register('rates', { required: true })}
                placeholder="Rates"
                icon={Percent}
              />
            </FormControl>
          </Stack>

          <FormControl isInvalid={!!errors.avatar}>
            <UserFormLabel htmlFor="avatar">Avatar</UserFormLabel>
            <AvatarUpload setAvatarUrl={setAvatar} avatar={avatar} />
          </FormControl>
          <FormControl isInvalid={!!errors.parent}>
            <Flex alignItems={'center'} gap={2} mb={2}>
              <IconButton
                bg="transparent"
                aria-label="Add Parent"
                w="24px"
                h="24px"
                borderRadius="full"
                minW="24px"
                onClick={onParentModalOpen}
                _hover={{ bg: colors.grey }}
                icon={<Plus color={colors.white} size={24} />}
              />
              <UserFormLabel m="0" htmlFor="parent">
                Parent
              </UserFormLabel>
            </Flex>
            <Flex
              alignItems="center"
              flexWrap="wrap"
              minH="60px"
              bg={colors.grey}
              p="10px"
              w="100%"
              borderRadius="0.375rem"
            >
              {parent && (
                <Tag alignItems="center" gap={2} bg="#7e38d1" p={2}>
                  <Avatar src={parent.avatar} size="sm" />
                  <Text color={colors.white} fontSize="14px">
                    {parent.first_name} {parent?.last_name ?? ''}
                  </Text>
                </Tag>
              )}
            </Flex>

            {isParentModalOpen && (
              <BaseModal
                isOpen={isParentModalOpen}
                onClose={onParentModalClose}
                header={<ModalTitle title="Add Parent" />}
                body={
                  <ModalBodyContent
                    users={potentialParentUIDs ?? []}
                    selectedUIDs={checkedParentUID}
                    setSelectedUIDs={
                      setParentUID as Dispatch<
                        SetStateAction<string | string[]>
                      >
                    }
                    isMultiSelect={false}
                  />
                }
                footer={<Button onClick={handleParentSubmit}>Close</Button>}
              />
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.children}>
            <Flex alignItems={'center'} gap={2} mb={2}>
              <IconButton
                bg="transparent"
                aria-label="Add Children"
                w="24px"
                h="24px"
                borderRadius="full"
                minW="24px"
                onClick={onChildrenModalOpen}
                _hover={{ bg: colors.grey }}
                icon={<Plus color={colors.white} size={24} />}
              />
              <UserFormLabel m="0" htmlFor="children">
                Children
              </UserFormLabel>
            </Flex>
            <Flex
              alignItems="center"
              flexWrap="wrap"
              minH="60px"
              bg={colors.grey}
              p="10px"
              w="100%"
              borderRadius="0.375rem"
            >
              {children && children.length > 0 && (
                <Flex flexWrap="wrap" gap={2}>
                  {children.map((child: IUser) => {
                    return (
                      <Tag
                        key={child.uid}
                        alignItems="center"
                        gap={2}
                        bg="#7e38d1"
                        p={2}
                      >
                        <Avatar src={child?.avatar} size="sm" />
                        <Text color={colors.white} fontSize="14px">
                          {child?.first_name} {child?.last_name ?? ''}
                        </Text>
                      </Tag>
                    )
                  })}
                </Flex>
              )}
            </Flex>
            {isChildrenModalOpen && (
              <BaseModal
                isOpen={isChildrenModalOpen}
                onClose={onChildrenModalClose}
                header={<ModalTitle title="Add Children" />}
                body={
                  <ModalBodyContent
                    users={potentialChildrenUIDs ?? []}
                    selectedUIDs={checkedChildrenUIDs}
                    setSelectedUIDs={
                      setChildrenUIDs as Dispatch<
                        SetStateAction<string | string[]>
                      >
                    }
                    isMultiSelect={true}
                  />
                }
                footer={<Button onClick={handleChildrenSelect}>Close</Button>}
              />
            )}
          </FormControl>
          <Divider />
          <FormControl>
            <ContractInput
              initialContracts={user?.contracts ?? []}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
            />
          </FormControl>
          <Divider />
          <FormControl>
            <DocumentManager
              initialHubs={documents ?? []}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
            />
          </FormControl>
          <Divider />
          <Flex w="100%" justifyContent="flex-end" mt="50px">
            <Button
              type="submit"
              size="md"
              width="full"
              isDisabled={isSubmitDisabled}
              isLoading={loading}
              w="150px"
            >
              Submit
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  )
}

export default UserForm
