import { useEffect, useState } from 'react'
import {
  Flex,
  IconButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
} from '@chakra-ui/react'
import { Plus, X } from 'lucide-react'
import colors from '../../constants/colors'
import { UserFormLabel } from '../commonComponents/UserFormLabel'
import UserInput from './UserInput'
import { Contract, UserFormData } from '../../types/types'
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

interface ContractInputProps {
  initialContracts?: Contract[]
  setValue: UseFormSetValue<UserFormData>
  setError: UseFormSetError<UserFormData>
  clearErrors: UseFormClearErrors<UserFormData>
}
export const monthOrder = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const

export const ContractInput = ({
  initialContracts = [],
  setValue,
  setError,
  clearErrors,
}: ContractInputProps) => {
  const [contracts, setContracts] = useState<Contract[]>([])
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

  const addContract = () => {
    const newContract = {
      name: `Contract ${contracts.length + 1}`,
      value: {
        january: '',
        february: '',
        march: '',
        april: '',
        may: '',
        june: '',
        july: '',
        august: '',
        september: '',
        october: '',
        november: '',
        december: '',
      },
    }
    const updatedContracts = [...contracts, newContract]
    setContracts(updatedContracts)
    setValue('contracts', updatedContracts)
  }

  const removeContract = (index: number) => {
    const updatedContracts = contracts.filter((_, i) => i !== index)
    setContracts(updatedContracts)
    setValue('contracts', updatedContracts)
  }

  const handleNameChange = (index: number, name: string) => {
    const updatedContracts = [...contracts]
    updatedContracts[index].name = name
    setContracts(updatedContracts)
  }

  const handleMonthValueChange = (
    index: number,
    month: keyof Contract['value'],
    value: string,
  ) => {
    const updatedContracts = [...contracts]
    updatedContracts[index].value[month] = value
    setContracts(updatedContracts)
    setValue('contracts', updatedContracts)
  }
  const validateFields = () => {
    let valid = true
    const newErrors = {}

    contracts.forEach((contract, cIndex) => {
      if (!contract.name.trim()) {
        // @ts-ignore
        newErrors[`contract-${cIndex}-name`] = true
        valid = false
      } else {
        // @ts-ignore
        newErrors[`contract-${cIndex}-name`] = false
      }
    })

    setErrors(newErrors)
    return valid
  }

  const handleOnBlur = () => {
    if (validateFields()) {
      setValue('contracts', contracts)
    } else {
      setError('contracts', { message: 'Please fill in all fields' })
    }
  }
  useEffect(() => {
    setContracts(initialContracts)
  }, [initialContracts])

  return (
    <Flex direction="column" gap={4} w={{ base: '100%', md: '50%' }}>
      <Flex alignItems="center" gap={2}>
        <IconButton
          bg="transparent"
          aria-label="Add Contract"
          w="24px"
          h="24px"
          borderRadius="full"
          minW="24px"
          _hover={{ bg: colors.grey }}
          icon={<Plus color={colors.white} size={24} />}
          onClick={addContract}
        />
        <UserFormLabel m="0" htmlFor="contracts">
          Contracts
        </UserFormLabel>
      </Flex>

      {contracts.map((contract, index) => (
        <Flex key={index} gap={2} mt={4}>
          <UserInput
            placeholder="Contract Name"
            value={contract.name}
            isError={errors[`contract-${index}-name`]}
            errorMessage="Contract name is required"
            onChange={(e) => {
              handleNameChange(index, e.target.value)
              setErrors((prev) => ({
                ...prev,
                [`contract-${index}-name`]: false,
              }))
              clearErrors('contracts')
            }}
            onBlur={handleOnBlur}
          />
          <Popover placement="left-start">
            <PopoverTrigger>
              <Button
                bg={colors.greenPastele}
                color={colors.white}
                _hover={{ bg: colors.greenLight }}
              >
                Values
              </Button>
            </PopoverTrigger>
            <PopoverContent bg={colors.greyDark} maxW="200px">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                {monthOrder.map((month) => (
                  <Flex
                    key={month}
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Text color={colors.white}>
                      {month.charAt(0).toUpperCase() + month.slice(1)}
                    </Text>
                    <UserInput
                      placeholder="Value"
                      width="75px"
                      type="number"
                      justifyContent="flex-end"
                      value={contract.value[month as keyof Contract['value']]}
                      onChange={(e) =>
                        handleMonthValueChange(
                          index,
                          month as keyof Contract['value'],
                          e.target.value,
                        )
                      }
                      ml={2}
                    />
                  </Flex>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <IconButton
            aria-label="Remove Contract"
            icon={<X color="red" size={24} />}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            onClick={() => removeContract(index)}
          />
        </Flex>
      ))}
    </Flex>
  )
}
