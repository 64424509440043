import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Contract } from '../../types/types'
import { generateChartData, generateChartOptions } from './utils'
import { useBreakpointValue } from '@chakra-ui/react'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const AnimatedChart = ({ contracts }: { contracts: Contract[] }) => {
  const data = generateChartData(contracts)
  const legendBoxWidth = useBreakpointValue({ base: 40, md: 40 })
  const legendBoxHeight = useBreakpointValue({ base: 20, md: 20 })
  const lineWidth = useBreakpointValue({ base: 2, md: 2 })
  const pointRadius = useBreakpointValue({ base: 1, md: 1 })

  const chartOptions = generateChartOptions({
    legendBoxWidth,
    legendBoxHeight,
    lineWidth,
    pointRadius,
  })

  return <Line options={chartOptions} data={data} />
}

export default AnimatedChart
