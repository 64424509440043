import { Avatar, Divider, Flex, Text } from '@chakra-ui/react'
import { Dispatch, Fragment, SetStateAction } from 'react'
import { IUser } from '../../types/types'
import colors from '../../constants/colors'
import UserInput from './UserInput'
import { CustomCheckbox } from '../commonComponents/CustomCheckBox'

interface ModalBodyContentProps {
  users: IUser[]
  selectedUIDs: string[] | string
  setSelectedUIDs: Dispatch<SetStateAction<string[] | string>>
  isMultiSelect?: boolean
}

export const ModalBodyContent = ({
  users,
  selectedUIDs,
  setSelectedUIDs,
  isMultiSelect = false,
}: ModalBodyContentProps) => {
  const handleChange = (user: IUser) => {
    if (isMultiSelect) {
      // Logic for multi-select (children)
      const updatedUIDs = Array.isArray(selectedUIDs)
        ? selectedUIDs.includes(user.uid)
          ? selectedUIDs.filter((uid) => uid !== user.uid)
          : [...selectedUIDs, user.uid]
        : [user.uid]
      setSelectedUIDs(updatedUIDs)
    } else {
      // Logic for single select (parent)
      setSelectedUIDs(user.uid === selectedUIDs ? '' : user.uid)
    }
  }

  return (
    <Flex flexDir="column">
      <UserInput placeholder="Search" bg={colors.greyLight} />
      <Divider my={2} />
      {users?.map((user) => (
        <Fragment key={user.uid}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            key={user.uid}
          >
            <Flex alignItems="center" gap={2}>
              <Avatar src={user.avatar} />
              <Text color={colors.white} fontSize="14px">
                {user.first_name} {user?.last_name ?? ''}
              </Text>
            </Flex>
            <CustomCheckbox
              isChecked={
                isMultiSelect
                  ? Array.isArray(selectedUIDs) &&
                    selectedUIDs.includes(user.uid)
                  : selectedUIDs === user.uid
              }
              onChange={() => handleChange(user)}
            />
          </Flex>
          <Divider my={2} />
        </Fragment>
      ))}
    </Flex>
  )
}
