import { useAllUsers } from '../hooks/useSwr'
import { Box, Spinner } from '@chakra-ui/react'
import UsersTable from '../components/UsersTable'
import { TableHeader } from '../components/UsersTable/TableHeader'
import colors from '../constants/colors'

export default function Admin() {
  const { users: data, isError, isLoading } = useAllUsers()

  if (isError) {
    console.error('Error fetching user data:', isError)
    return null
  }

  return (
    <Box w="100%" pt="100px" px="30px">
      {isLoading ? (
        <Spinner
          color={colors.greyLight}
          w="80px"
          h="80px"
          position="absolute"
          fontWeight="bold"
          top="50%"
          left="55%"
        />
      ) : (
        <>
          <TableHeader />
          {data && <UsersTable users={data} />}
        </>
      )}
    </Box>
  )
}
