import React, { forwardRef } from 'react'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'
import emotionStyled from '@emotion/styled'
import colors from '../../constants/colors'

const StyledCheckbox = emotionStyled(Checkbox)`
  border-radius: 3px;
  .css-1ydjfm6[aria-checked=true], .css-1ydjfm6[data-checked] {
    background-color: ${colors.white};
  }
  `

interface CustomCheckboxProps extends CheckboxProps {
  register?: UseFormRegisterReturn
}

export const CustomCheckbox = forwardRef<HTMLInputElement, CustomCheckboxProps>(
  ({ register, ...props }, ref) => {
    return (
      <StyledCheckbox
        ref={ref}
        {...register}
        iconColor="transparent"
        {...props}
      >
        {props.children}
      </StyledCheckbox>
    )
  },
)

CustomCheckbox.displayName = 'CustomCheckbox'
