import React from 'react'
import { FormLabel, FormLabelProps } from '@chakra-ui/react'
import colors from '../../constants/colors'

interface UserFormLabelProps extends FormLabelProps {
  color?: string
}

export const UserFormLabel: React.FC<UserFormLabelProps> = ({
  color = colors.greyText,
  ...props
}) => {
  return <FormLabel {...props} color={color} />
}
